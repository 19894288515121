import '../styles/globals.css';
import type {AppProps} from 'next/app';
import {useEffect} from 'react';
import {useRouter} from 'next/dist/client/router';
import * as Fathom from 'fathom-client';
import {QueryClient, QueryClientProvider} from 'react-query';
import {WalletProvider} from '../components/useWeb3';

const queryClient = new QueryClient();

function MyApp({Component, pageProps}: AppProps) {
  const router = useRouter();
  const skipAnalytics = pageProps && pageProps.skipAnalytics;

  useEffect(() => {
    if (skipAnalytics) {
      return;
    }

    Fathom.load('HKRZCDGV', {
      includedDomains: ['codemakes.art'],
      url: 'https://dragon.codemakes.art/script.js',
    });

    const onRouteChangeComplete = () => Fathom.trackPageview();

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events, skipAnalytics]);

  return (
    <WalletProvider>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </WalletProvider>
  );
}
export default MyApp;
